import styled from "styled-components"

import { HexagonTitleMain, col, containerSideOffset, SectionTextMain, SectionTitleMain } from "./common.styles"

export const AboutBHMain = styled.div`
padding: 208px 0 68px;
background-color: ${props => props.theme.colors.secondary300};

${props => props.theme.max('mac', (props) => (`
  padding: 170px 0 120px;
`), props)}

${props => props.theme.max('sm', (props) => (`
  padding: 93px 0 90px;
`), props)}

.flex-wrap {
  align-items: flex-start;
}

.main-col {
  ${containerSideOffset()}
  ${col(8, 'c')}
  padding-bottom: 154px;
  ${props => props.theme.max('mac', (props) => (`
    padding-bottom: 75px;
`), props)}

  ${props => props.theme.max('md', (props) => (`
    ${col(43)}
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
    padding-bottom: 0;
  `), props)}
}

.side-col {
  margin-left: auto;
  ${containerSideOffset('right', 2.6)}
  ${col(7.35, 'c')}

  ${props => props.theme.max('md', (props) => (`
    ${col(48)}
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    ${containerSideOffset('left', 4)}
    ${col(100)}
    margin-right: 0;
  `), props)}

  .img-wrap {
    padding-top: 100px;

  ${props => props.theme.max('sm', (props) => (`
    padding-top: 80px;
  `), props)}
  }
}

${SectionTitleMain} {
  margin-bottom: .38em;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: .98em;
  `), props)}
}

${SectionTextMain} {
  margin-bottom: 2.1em;

  ${props => props.theme.min('sm', (props) => (`
    max-width: 520px;
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 0;
  `), props)}
  
  &:not(.large-text) {
    ${props => props.theme.min('sm', (props) => (`
      margin-left: auto;
    `), props)}
  }

  &.large-text {
    max-width: 480px;

    ${props => props.theme.max('sm', (props) => (`
      p {
        margin-bottom: 1em;
      }
  `), props)}  
  }
}

${HexagonTitleMain} {
  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 80px;
  `), props)}
}

.desktop {
  ${props => props.theme.max('sm', (props) => (`
    display: none;
  `), props)}
}

.mobile {
  ${props => props.theme.min('sm', (props) => (`
    display: none;
  `), props)}
}

&.cutout-top {
  &:before {
    left: 48%;
    width: 52%;
    
    ${props => props.theme.max('lg', () => (`
      height: 90px;
    `))}
  }
}
`