import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"

import { useCountUp } from "react-countup"

import { HexagonTitle } from "../elements/hexagon-title"
import { SplitText } from "../helpers/common"

import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"

import { Container } from "../styles/common.styles"
import { UsInNumbersMain } from "../styles/us-in-numbers.styles"

export const UsInNumbersItem = ({
  number,
  symbol,
  title,
  text,
  i,
  itemsNum,
  isVisible,
}) => {
  const { countUp, start, reset } = useCountUp({
    start: 1,
    end: number,
    duration: 3,
  })

  React.useEffect(() => {
    if (isVisible) {
      start()
    }

    if (!isVisible) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <div className={`uin-item ${i + 1 === itemsNum ? "last" : ""}`}>
      <div className="uin-num">
        <span className="uin-num-txt">{`${
          countUp < 10 ? "0" : ""
        }${countUp}`}</span>
        <span className="uin-num-symb">{symbol}</span>
      </div>

      <div className="uin-content">
        <div className="uin-title">
          <SplitText text={title} />
        </div>
        <p className="uin-text uin-text-sm">
          <SplitText text={text} />
        </p>
      </div>
    </div>
  )
}

export const UsInNumbersSection = ({
  hexagonData: { name, sectionNum },
  items,
  yearsNumber,
  yearsText,
  yearsSymbol,
  footnote,
  linkData,
  yearsTitle,
  title,
  text,
}) => {
  const [isVisible, setIsVisible] = React.useState(false)

  const { countUp, start, reset } = useCountUp({
    start: 1,
    end: yearsNumber,
    duration: 3,
  })

  function onChange(isVisible) {
    if (isVisible) {
      start()
    } else {
      reset()
    }
    setIsVisible(isVisible)
  }

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ top: 100, bottom: 100 }}
      onChange={onChange}
    >
      <UsInNumbersMain className={`to-animate ${isVisible ? "is-in-vp" : ""}`}>
        <Container>
          <HexagonTitle number={sectionNum}>{name}</HexagonTitle>

          <div className="overview-intro">
            <SectionTitle
              text={title}
              responsive={[{ charLimit: 8, breakpoint: 767 }]}
            />
            <SectionText text={text} variation="large-text" delay={600} />

            <AcfLink
              linkData={linkData}
              className={`btn secondary mobile hp-about-link`}
            >
              <BtnInner text={linkData.linkText} />
            </AcfLink>
          </div>
        </Container>

        <div className="flex-wrap">
          <div className="main-col">
            <div className="uin-num-lg">
              <span>{countUp}</span>
              <span className={"uin-sym-lg"}>{yearsSymbol}</span>
            </div>
            <div className="uin-text-lg">
              {yearsTitle && <div className="uin-title">{yearsTitle}</div>}
              <div className="uin-text">
                <SplitText text={yearsText} />
              </div>
            </div>
          </div>

          <div className="side-col">
            {items.map(({ number, symbol, title, text }, i) => (
              <UsInNumbersItem
                key={i}
                number={number}
                symbol={symbol}
                title={title}
                text={text}
                i={i}
                itemsNum={items.length}
                isVisible={isVisible}
              />
            ))}
            <div className="uin-footnote">
              <div className="uin-fn-in">{footnote}</div>
            </div>
          </div>
        </div>
      </UsInNumbersMain>
    </VisibilitySensor>
  )
}
