import styled from "styled-components"

import { SectionTextMain, HexagonTitleMain, SectionTitleMain, containerSideOffset, col, Container } from "./common.styles"

export const BhExperienceMain = styled.div`
padding-top: 281px;
padding-bottom: 179px;

${props => props.theme.max('md', () => (`
    padding: 100px 0 100px;
`))}

${props => props.theme.max('sm', () => (`
    padding: 50px 0 50px;
`))}

${HexagonTitleMain} {
  margin-bottom: 80px;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 40px;
  `))}
}

${SectionTitleMain} {
  margin-bottom: .34em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 1.5em;
  `))}
}

.image-col {
  ${containerSideOffset('right', 2.7)}
  ${col(4.3, 'c')}
  margin-left: auto;
  margin-top: -134px;

  ${props => props.theme.max('alg', () => (`
    margin-top: -210px;
  `))}

  ${props => props.theme.max('md', () => (`
    ${col(30)}
    order: -1;
    margin-top: 0;
    margin-bottom: -100px;
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    margin-bottom: 70px;
  `))}
}

${SectionTextMain} {
  max-width: 480px;
}

.main-col-t {
  ${containerSideOffset()}
  ${col(13, 'c')}

  ${props => props.theme.max('md', () => (`
    ${col(100)}
  `))}

}

.main-col-l {
  ${col(50)}

  ${props => props.theme.max('md', () => (`
    ${col(48)}
    margin-right: auto;
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

.main-col-r {
  ${containerSideOffset('right', 6)}
  ${col(6, 'c')}
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    ${col(48)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

.col-l-text {
  margin-left: 33%;

  ${props => props.theme.max('mac', () => (`
    margin-left: 26%;
  `))}

  ${props => props.theme.max('md', () => (`
    margin-left: 0;
    ${containerSideOffset()}
  `))}

}

.image-wrap {
  max-width: 88%;
  margin-top: 138px;

  ${props => props.theme.max('md', () => (`
    margin-top: 100px;
  `))}

  ${props => props.theme.max('sm', () => (`
    margin-top: 50px;
    width: 100%;
  `))}

  &.mobile-only {
    ${col(100)}
  }
}

.bh-link-position {
  margin-top: 11px;

${props => props.theme.max('md', () => (`
  margin-top: 0;
`))}
}

`