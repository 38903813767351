import React from 'react'
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"
import { RevealImage } from "../elements/reveal-image"

import { BhExperienceMain } from "../styles/bh-experience.styles"
import { seoClassPrefix } from "../helpers/common"

export const BhExperienceSection = ({
  title,
  text1,
  text2,
  linkData,
  image1,
  image2,
  hexagonData: {
    name,
    sectionNum,
  },
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <BhExperienceMain className={`flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="main-col-t">
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>
            <SectionTitle text={title} responsive={[{ breakpoint: 767, charLimit: 20 }, { breakpoint: 480, charLimit: 10 }]} />
          </div>
          <div className="image-col">
            <RevealImage image={image2} variation={'from-left'} />
          </div>

          <div className="main-col-l">
            <div className="col-l-text">
              <SectionText text={text1} variation="large-text" delay={1000} />
            </div>
            <div className="image-wrap desktop-only">
              <RevealImage image={image1} variation={'from-left'} />
            </div>
          </div>
          <div className="main-col-r">
            <SectionText text={text2} variation="large-text" delay={1000} />
            <AcfLink linkData={linkData} className={`btn secondary mobile ${seoClassPrefix}hp-bh-experience-link bh-link-position`}>
              <BtnInner text={linkData.linkText} />
            </AcfLink>
          </div>
          <div className="image-wrap mobile-only">
            <RevealImage image={image1} variation={'from-left'} />
          </div>
        </BhExperienceMain>
      }
    </VisibilitySensor>
  )
}
