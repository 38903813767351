import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"

import { AboutBHMain } from "../styles/about-bh.styles"
import { seoClassPrefix } from "../helpers/common"
import { RevealImage } from "../elements/reveal-image"
import { HexagonTitle } from "../elements/hexagon-title"

export const AboutBHSection = ({ title,
  linkData,
  text,
  image,
  hexagonData: {
    name,
    sectionNum
  },
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <AboutBHMain className={`cutout-top to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="flex-wrap section-wrap">
            <div className="main-col">
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>
              
              <SectionTitle text={title} />

              <SectionText text={text} variation="large-text" delay={1000} />

              <AcfLink linkData={linkData} className={`btn secondary ${seoClassPrefix}hp-about-link`}>
                <BtnInner text={linkData.linkText} delay={1200} />
              </AcfLink>
            </div>
            <div className="side-col">
              <div className="img-wrap">
                <RevealImage image={image} variation={'from-left'} />
              </div>
            </div>
          </div>
        </AboutBHMain>
      }
    </VisibilitySensor>
  )
}
