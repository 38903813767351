import styled from "styled-components"

import {
  col,
  containerSideOffset,
  HexagonTitleMain,
  SectionTitleMain,
  SectionTextMain,
} from "../styles/common.styles"

const initialD = 50

export const UsInNumbersMain = styled.div`
  position: relative;
  padding: 140px 0 38px;

  ${props =>
    props.theme.max(
      "sm",
      () => `
  padding: 63px 0 95px;
`
    )}

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: ${props => props.theme.dimensions.cutoutWpos}%;
    height: 47.5%;
    width: 1px;
    transform: translate(1px, 0);
    background-color: ${props => props.theme.colors.black};
    opacity: 0.1;

    ${props =>
      props.theme.max(
        "md",
        props => `
    content: none;
  `,
        props
      )}
  }

  .overview-intro {
    max-width: 1040px;
    margin-bottom: 64px;

    ${props =>
      props.theme.max(
        "sm",
        () => `
    margin-bottom: 44px;
  `
      )}

    ${SectionTitleMain} {
      margin-bottom: 0.34em;

      ${props =>
        props.theme.max(
          "sm",
          () => `
      margin-bottom: 1em;
    `
        )}
    }

    ${SectionTextMain} {
      margin-bottom: 2.3em;
    }
  }

  .main-col {
    ${containerSideOffset()}
    ${col(5, "c")}
  margin-right: auto;

    ${props =>
      props.theme.max(
        "md",
        props => `
    text-align: center;
    ${col(38)}
  `,
        props
      )}

    ${props =>
      props.theme.max(
        "sm",
        props => `
    ${col(100)}
    text-align: left;
    padding-bottom: 25px;
  `,
        props
      )}
  }

  .side-col {
    ${containerSideOffset("right", 4)}
    ${col(7.4, "c")}
  padding-top: 65px;
    margin-left: auto;

    ${props =>
      props.theme.max(
        "mac",
        () => `
    ${col(9, "c")}
  `
      )}

    ${props =>
      props.theme.max(
        "md",
        () => `
    ${col(62)}
  `
      )}

  ${props =>
      props.theme.max(
        "sm",
        props => `
    ${col(100)}
    display: flex;
    flex-direction: column;
    padding-top: 35px;
  `,
        props
      )}
  }

  .uin-num,
  .uin-num-lg {
    font-family: ${props => props.theme.fonts.tertiary};
    font-weight: 100;
    color: ${props => props.theme.colors.secondary};
  }

  .uin-num-lg {
    display: inline-block;
    transform: translate(-0.05em, 0.2em);
    font-size: 340px;
    line-height: 1;

    ${props =>
      props.theme.max(
        "mac",
        props => `
    font-size: 360px;
  `,
        props
      )}

    ${props =>
      props.theme.max(
        "md",
        () => `
    font-size: 225px;
  `
      )}

  ${props =>
      props.theme.max(
        "sm",
        props => `
    font-size: 220px;
    line-height: 0.85;
  `,
        props
      )}
  }

  .uin-sym-lg {
    vertical-align: 50%;
    font-size: 185px !important;
    line-height: 1;

    ${props =>
      props.theme.max(
        "mac",
        props => `
    font-size: 180px;
  `,
        props
      )}

    ${props =>
      props.theme.max(
        "md",
        () => `
    font-size: 50%;
    vertical-align: 10%;
  `
      )}

  ${props =>
      props.theme.max(
        "sm",
        props => `
    font-size: 50%;
    line-height: 0.85;
  `,
        props
      )}
  }

  .uin-text {
    font-size: 16px;
    opacity: 0.7;
    margin-bottom: 0;
    line-height: 1.5;

    ${props =>
      props.theme.max(
        "mb",
        () => `
    min-height: 3.8em;
  `
      )}
  }

  .uin-text-lg {
    max-width: 385px;
    padding-top: 46px;

    ${props =>
      props.theme.max(
        "md",
        props => `
    margin: 0 auto;
    text-align: left;
  `,
        props
      )}

    ${props =>
      props.theme.max(
        "sm",
        props => `
    margin-left: 0;
  `,
        props
      )}
  }

  .uin-text-sm {
    ${props =>
      props.theme.min(
        "sm",
        () => `
    max-width: 323px;
  `
      )}
  }

  .uin-footnote {
    font-size: 14px;
    line-height: 1.5;
    opacity: 0.5;
    margin-left: 45.6%;

    ${props =>
      props.theme.max(
        "ls",
        () => `
    margin-left: 48%;
  `
      )}

    ${props =>
      props.theme.max(
        "sm",
        () => `
    margin-left: 0;
    order: 10;
  `
      )}
  }

  .uin-num {
    text-align: center;
    font-size: 92px;

    ${col(45.6)}

    ${props =>
      props.theme.max(
        "ls",
        props => `
    ${col(48)}
  `,
        props
      )}

  ${props =>
      props.theme.max(
        "mac",
        props => `
    font-size: 72px;
  `,
        props
      )}

  ${props =>
      props.theme.max(
        "sm",
        props => `
    ${col(100)}
    text-align: left;
    font-size: 92px;
  `,
        props
      )}
  }

  .uin-content {
    ${col(100 - 45.6)}
    padding-top: 5px;

    ${props =>
      props.theme.max(
        "ls",
        props => `
    ${col(52)}
  `,
        props
      )}

    ${props =>
      props.theme.max(
        "sm",
        props => `
    ${col(100)}
    margin-top: -.55em;
  `,
        props
      )}
  }

  .uin-num-symb {
    display: inline-block;
    font-size: 56px;
    min-width: 0.5em;
    transform: translate(0.05em, -0.3em);

    ${props =>
      props.theme.max(
        "mac",
        props => `
    font-size: 40px;
  `,
        props
      )}
  }

  .uin-title {
    font-size: 20px;
    margin-bottom: 0.7em;
  }

  .uin-item {
    display: flex;
    margin-bottom: 100px;

    &.last {
      margin-bottom: 20px;
    }

    ${props =>
      props.theme.max(
        "sm",
        props => `
    flex-wrap: wrap;
    margin-bottom: 40px;
  `,
        props
      )}
  }

  ${HexagonTitleMain} {
    margin-bottom: 41px;

    ${props =>
      props.theme.max(
        "sm",
        () => `
    margin-bottom: 60px;
  `
      )}
  }

  &.to-animate {
    .uin-footnote,
    .uin-content {
      overflow: hidden;
    }

    .uin-fn-in,
    .uin-text-sm,
    .uin-title {
      transform: translate(-100%, 0);
      opacity: 0;
    }

    .main-col {
      opacity: 0;
      transform: translate(0, 100px);
    }
  }

  &.to-animate:not(.is-in-vp) {
    .main-col,
    .uin-fn-in,
    .uin-text-sm,
    .uin-title {
      transition-delay: 0ms !important;
    }
  }

  &.to-animate.is-in-vp {
    .uin-fn-in,
    .uin-text-sm,
    .uin-title {
      transition: ${800}ms ease ${initialD}ms;
      transform: translate(0);
      opacity: 1;
    }

    .uin-text-sm {
      transition-delay: ${initialD + 200}ms;
    }

    .main-col {
      transition: ${800}ms ease ${initialD + 100}ms;
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`
