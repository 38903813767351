import React from "react"
import Img from "gatsby-image"
import { animateScroll } from "react-scroll"
import VisibilitySensor from "react-visibility-sensor"
import classNames from "classnames"

import ScrollDownIcon from "../assets/svg/scroll-down.svg";

import { HeroMain, HeroImgWrap } from "../styles/hero.styles"
import { seoClassPrefix, SplitText, unoptimzeGatsbyImageSource } from "../helpers/common"

import { usePreloaderState } from "../context/global-preloader-context"
import { useAgreementState } from "../context/global-agreement-context";

export const HeroSection = ({ heroTitle, heroImage }) => {

  const { preloaded } = usePreloaderState();

  const { popupTermsStatus } = useAgreementState();

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
      {({ isVisible }) =>
        <div className={classNames('to-animate', {
          "is-in-vp": isVisible && 'accepted' === popupTermsStatus,
          "loading-done": !preloaded,
        })}>
          <HeroMain>
            <h1 className="hero-title">
              <SplitText text={heroTitle} multiWrap={1} />
            </h1>

            <div className="hero-inner">
              <HeroImgWrap>
                <div className="hero-img-inner">
                  <div className="hero-img-tc">
                    <Img fluid={unoptimzeGatsbyImageSource(heroImage)} />
                  </div>
                </div>
              </HeroImgWrap>

              <div className="sc-idc">
                <div className="sc-idc-inner">
                  <button
                    className={`sc-idc-btn ${seoClassPrefix}scroll-down-btn`}
                    onClick={() => {
                      animateScroll.scrollTo(typeof window !== 'undefined' ? window.innerHeight * 1.2 : 1000);
                    }}
                  >
                    <ScrollDownIcon />
                  </button>
                </div>
              </div>
            </div>
          </HeroMain>
        </div>
      }
    </VisibilitySensor>
  )
}