import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import CHex from "../assets/svg/chex.svg"
import { DotMapImage } from "../query-components/dot-map-bg"

import { atAGlanceTitleResponsive, SplitText, SvgIcon } from "../helpers/common"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"

import { IconListMain } from "../styles/icon-list.styles"
import { Container } from "../styles/common.styles"

export const IconListSection = ({
  cutouts,
  title,
  list = [],
  hexagonData: {
    name,
    sectionNum
  },
}) => {
  const initialD = 400;

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <IconListMain className={`to-animate ${isVisible ? 'is-in-vp' : ''} ${cutouts}`}>
          <div className="full-bg-wrap">
            <DotMapImage />
          </div>

          <Container>
            <HexagonTitle number={sectionNum} className="light" style={{ transitionDelay: '600ms' }}>
              {name}
            </HexagonTitle>

            <SectionTitle text={title} delay={800} responsive={atAGlanceTitleResponsive} />

            <div className="row list">
              {list.map(({ itemIcon, title }, i) => (
                <div key={i} className={`pad-col item-wrap ${i % 3 === 0 ? 'fd' : ''}`}>
                  <div className="item">
                    <div className={`item-icon-wrap ${itemIcon}`}>
                      <div className="item-icon-svg" style={{ transitionDelay: `${initialD + 100 * i}ms` }}>
                        <CHex />
                      </div>
                      <div className="item-icon" style={{ transitionDelay: `${initialD + 100 + 100 * i}ms` }}>
                        <SvgIcon icon={itemIcon} />
                      </div>
                    </div>
                    <div className="item-title" style={{ transitionDelay: `${initialD + 200 + 100 * i}ms` }}>
                      <SplitText text={title} type="span" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </IconListMain>
      }
    </VisibilitySensor>
  )
}