import styled from "styled-components"

import { HexagonTitleMain, col, containerSideOffset, SectionTextMain, SectionTitleMain } from "./common.styles"

export const WorkingAtBhMain = styled.div`
padding: 141px 0 237px;
background-color: ${props => props.theme.colors.secondary300};

${props => props.theme.max('lg', (props) => (`
    padding: 100px 0 140px;
`), props)}

${props => props.theme.max('sm', (props) => (`
    padding: 50px 0 50px;
`), props)}

${HexagonTitleMain} {
  margin-bottom: 77px;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 40px;
  `))}
}

.flex-wrap {
  ${props => props.theme.max('ls', (props) => (`
    align-items: center;
  `), props)}
}

.main-col {
  ${containerSideOffset()}
  ${col(7, 'c')}
  margin-right: auto;

  ${props => props.theme.max('md', (props) => (`
    ${col(48)}
  `), props)}
}

${SectionTitleMain} {
  margin-bottom: .36em;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 1.3em;
  `), props)}
}

${SectionTextMain} {
  max-width: 560px;
  margin-bottom: 2.3em;
  
  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 1.9em;
  `), props)}
}


.side-col {
  margin-left: auto;
  ${containerSideOffset('right', 2)}
  ${col(9, 'c')}
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
  padding-top: 100px;
  ${props => props.theme.max('md', (props) => (`
    ${col(48)}
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    padding-top: 60px;
  `), props)}
}

.main-col,
.side-col {
  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
  `), props)}
}

.img-1 {
  flex: 0 0 calc(46% - 5px);
  max-width: calc(46% - 5px);
  ${props => props.theme.max('mob', (props) => (`
    flex: 0 0 70%;
    max-width: 70%;
  `), props)}
}

.img-2 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: auto;
  padding-top: 270px;
  ${props => props.theme.max('sf', (props) => (`
    padding-top: 140px;
  `), props)}

    ${props => props.theme.max('mob', (props) => (`
    flex: 0 0 70%;
    max-width: 70%;
    padding-top: 25px;
  `), props)}
}

`