import React from 'react'
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"
import { RevealImage } from "../elements/reveal-image"

import { WorkingAtBhMain } from "../styles/woking-at-bh.styles";
import { seoClassPrefix } from "../helpers/common"

export const WorkingAtBhSection = ({
  title,
  text,
  linkData,
  image1,
  image2,
  hexagonData: {
    name,
    sectionNum,
  },
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <WorkingAtBhMain className={`flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="main-col">
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>
            <SectionTitle text={title} responsive={[{ breakpoint: 480, charLimit: 10 }]} />
            <SectionText text={text} variation="large-text" delay={1000} />
            <AcfLink linkData={linkData} className={`btn secondary mobile ${seoClassPrefix}hp-working-at-bh-link`}>
              <BtnInner text={linkData.linkText} />
            </AcfLink>
          </div>
          <div className="side-col">
            <div className="img-1">
              <RevealImage image={image2} variation={'from-left'} />
            </div>
            <div className="img-2">
              <RevealImage image={image1} variation={'from-right'} />
            </div>
          </div>
        </WorkingAtBhMain>
      }
    </VisibilitySensor>
  )
}
