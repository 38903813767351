import styled from "styled-components"

import { col, HexagonTitleMain, SectionTitleMain } from "./common.styles"

export const IconListMain = styled.div`
position: relative;
padding-top: 140px;
padding-bottom: 138px;

${props => props.theme.max('sm', (props) => (`
  padding-top: 90px;
  padding-bottom: 48px;
`), props)}

.full-bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 139px;
  top: 0;
  left: 0;

  ${props => props.theme.max('sm', (props) => (`
    .gatsby-image-wrapper {
      display: none;
    }
  `), props)}

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    background-color: ${props => props.theme.colors.primary100};
  }
}

/* Corrections/offsets for certain browsers */
&.cutout-top {
  &:before {
    background-color: ${props => props.theme.colors.secondary300};
  }
}

&.cutout-bottom {
  .full-bg-wrap {
    &:before {
      height: calc(100% - 1px);
    }
  }
}

&.cutout-bottom.cutout-top {
  .full-bg-wrap {
    &:before {
      height: calc(100% - 2px);
      top: 1px;
    }
  }
}

.gatsby-image-wrapper {
  max-height: 1200px;
  height: 100%;
}

${HexagonTitleMain}, 
${SectionTitleMain} {
  position: relative;
  z-index: 5;
}

${HexagonTitleMain} {
  margin-bottom: 79px;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 40px;
  `))}
  
  .hexagon-num {
    color: ${props => props.theme.colors.secondary200};
  }

  svg {
    opacity: .2;
  }
}

${SectionTitleMain} {
  color: ${props => props.theme.colors.white};
  margin-bottom: 0.8em;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 1.2em;
  `), props)}
}

.list {
  position: relative;
  justify-content: space-between;
}

.item-wrap {
  ${col(29.4)}
  padding-top: 43px;
  padding-bottom: 53px;
  text-align: center;

  &.fd {
    ${props => props.theme.min('md', () => (`
      ${col(25.4)}
    `))}
  }
  
  
  ${props => props.theme.max('sm', (props) => (`
    ${col(50)}
  `), props)}

  ${props => props.theme.max('sf', (props) => (`
    ${col(100)}
    padding-top: 20px;
    padding-bottom: 23px;
  `), props)}
}

.item-icon-wrap {
  position: relative;
  margin-bottom: 0.9em;
  display: inline-block;
}

.item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item-title {
  color: ${props => props.theme.colors.secondary100};
  font-size: 26px;
  line-height: 1.2;
  margin-bottom: .6em;

  ${props => props.theme.max('md', () => (`
    font-size: 26px;
  `))}

  span {
    display: block;
  }
}

.item-text {
  color: ${props => props.theme.colors.white};
  line-height: 1.5;
}

&.to-animate {
  .item-icon-svg {
    transform: scale(1.5);
    opacity: 0;
  }
  
  .item-title {
    opacity: 0;
    transform: translate(0, 50px);
  }

  .item-icon {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
}

&.to-animate:not(.is-in-vp) {
  .item-title,
  .item-icon-svg,
  .item-icon {
    transition-delay: 0ms !important;
  }
}

&.to-animate.is-in-vp {
  /* .item-title,
  .item-icon-svg,
  .item-icon {
    transition: 800ms;
  } */
  
  .item-icon-svg {
    transform: scale(1);
    opacity: 1;
    transition: 800ms;
  }
  
  .item-title {
    opacity: 1;
    transform: translate(0, 0);
    transition: 800ms ease 500ms;
  }

  .item-icon {
    transition: 800ms ease 100ms;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  
}
`