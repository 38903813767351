import styled from "styled-components"

import { easeInOutS } from "./theme.styles"

const imgHeight = 80;

// animation timing variables
const imgP1Dur = 1500;
const imgP1Delay = 5;

const txtDur = 1300;
const txtDelay = 500 + imgP1Delay;
const txtStepDelay = 200;

const imgP2Dur = 1000;
const imgP2Delay = imgP1Dur;

export const HeroImgWrap = styled.div`
  position: relative;
  height: calc(${imgHeight}vh - ${props => props.theme.dimensions.headerHeightDt}px);
  max-height: 720px;
  margin: 0 auto;

  width: 50.2%;
  transform: translate(0, 15%);

  .to-animate.is-in-vp.loading-done & {
    transition: 
      transform ${imgP1Dur}ms ${easeInOutS} ${imgP1Delay}ms,
      width ${imgP2Dur}ms ease ${imgP2Delay}ms;
    width: 25.2%;
    transform: translate(0, 0);
  }

  ${props => props.theme.max('mac', (props) => (`
    max-height: 720px;
    width: 48%;
    .to-animate.is-in-vp.loading-done & {
      width: 37%;
    }
  `), props)}

  ${props => props.theme.max('md', () => (`
    width: 50%;
    .to-animate.is-in-vp.loading-done & {
      width: 40%;
    }
  `))}

  ${props => props.theme.max('sm', (props) => (`
    max-height: 402px;
    max-width: 240px;
    width: 80%;
    .to-animate.is-in-vp.loading-done & {
      width: 63%;
    }
  `), props)}

  @media (max-height: 490px) {
    height: calc(${imgHeight}vh - ${props => props.theme.dimensions.headerHeightDt}px);
  }

  .hero-img-tc {
    position: relative;
    position: absolute;
    height: 100%;
    width: 100%;
    
    transform: translate(0, 30%);
    
    .to-animate.is-in-vp.loading-done & {
      transition: ${imgP1Dur * .9}ms ease ${imgP1Delay}ms;
      transform: translate(0, 0);
    }
  }

  .hero-img-inner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    bottom: 0;
    left: 0;
    
    height: 0;

    .to-animate.is-in-vp.loading-done & {
      transition: ${imgP1Dur}ms ${easeInOutS} ${imgP1Delay}ms;
      height: 100%;
    }
  }

  .gatsby-image-wrapper {
    opacity: .3;
    position: absolute !important;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(${imgHeight}vh - ${props => props.theme.dimensions.headerHeightDt}px);

    transform: scale(1.15);
    
    .to-animate.is-in-vp.loading-done & {
      transition: ${imgP2Dur}ms ease ${imgP2Delay}ms;
      transform: scale(1);
    }
  }
`

export const HeroMain = styled.div`
position: relative;
background-color: ${props => props.theme.colors.primary};
color: ${props => props.theme.colors.white};

.hero-inner {
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: ${props => props.theme.dimensions.headerHeightDt}px;

  ${props => props.theme.max('sm', () => (`
    padding-top: 110px;
    padding-bottom: 100px;
  `))}
}

.sc-idc {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 30px;
  padding: 10px;
}

.sc-idc-inner {
  overflow: hidden;
  padding: 0 30px;

  svg {
    animation: scrollDownIndicate 3000ms ease 0ms infinite;
  }
}

@keyframes scrollDownIndicate {
  0% {
    transform: translate(0, -5px);
  }

  50% {
    transform: translate(0, 10px);
  }

  100% {
    transform: translate(0, -5px);

  }
}

.sc-idc-btn {
  cursor: pointer;
  appearance: none;
  border: none;
  padding: 5px 0 10px;
  background: none;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
  transition: opacity 400ms;

  transform: translate(0, -100%);
  
  .to-animate.is-in-vp.loading-done & {
    transition: 
      opacity 400ms,
      transform ${imgP2Dur}ms ease ${imgP2Delay}ms;
    transform: translate(0, 0);
  }

  &:hover {
    opacity: .7;
  }
}

.hero-title {
  position: absolute;
  font-size: 106px;
  text-align: center;
  line-height: .84;
  max-width: 1350px;
  width: 90%;
  z-index: 5;
  left: 50%;
  top: 52%;
  margin-top: -50px;
  transform: translate(-50%, -50%) scale(.8, .8);
  letter-spacing: -0.019em;
  margin-bottom: 0;

  ${props => props.theme.max('mac', () => (`
    margin-top: -10px;
  `))}

  .to-animate.is-in-vp.loading-done & {
    transition: ${imgP2Dur}ms ease ${imgP2Delay}ms;
    transform: translate(-50%, -50%) scale(1, 1);
  }

  ${props => props.theme.max('md', () => (`
    padding: 0 24px;
  `))}

  .p-wrap,
  .m-wrap {
    display: block;
  }

  ${props => props.theme.max('lt', () => (`
    font-size: 85px;
  `))}

  ${props => props.theme.max('lg', () => (`
    font-size: 80px;
  `))}

  ${props => props.theme.max('sm', () => (`
    font-size: 60px;
    top: 45%;

    .p-wrap {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .m-wrap {
      display: inline;
    }
  `))}

  ${props => props.theme.max('sf', () => (`
    font-size: 55px;
    line-height: .99;
    width: 100%;
  `))}

  ${props => props.theme.max('xs', () => (`
    font-size: 40px;
  `))}
}

.p-wrap {
  &>.m-wrap {
    margin-top: -.15em;
    overflow: hidden;

    ${props => props.theme.max('sm', () => (`
      overflow: visible;
    `))}

    &>.m-wrap {
      padding-bottom: .30em;
      transform: translate(0, 100%);

      .to-animate.is-in-vp.loading-done & {
        transition: ${txtDur * .5}ms ease-out ${txtDelay + (txtStepDelay * 4)}ms;
        transform: translate(0,0);
      }
    }

    &:nth-child(1) {
      &>.m-wrap {
        .to-animate.is-in-vp.loading-done & {
          transition-delay: ${txtStepDelay * 1}ms;
        }
      }
    }
    &:nth-child(2) {
      &>.m-wrap {
        .to-animate.is-in-vp.loading-done & {
          transition-delay: ${txtStepDelay * 2}ms;
        }
      }
    }
    &:nth-child(3) {
      &>.m-wrap {
        .to-animate.is-in-vp.loading-done & {
          transition-delay: ${txtStepDelay * 3}ms;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  ${props => props.theme.max('md', () => (`
    opacity: 0;

    .to-animate.is-in-vp.loading-done & {
      transition: ${txtDur}ms ease ${txtDelay}ms;
      opacity: 1;
    }
  `))}
}

`